.card-maximized {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100% - 54px);
  right: 0;
  /* bottom: 0; */
  background-color: #fff;
  z-index: 3;
  padding: 0;
  margin: 0;
  transition: none !important;
}

.card-maximized .card-body {
  /* min-height: 100%; */
  height: 0px;/* Safari issue fix (!)*/
  position: relative;
}

.card-body {
  position: relative;
}

.card-header {
  border-bottom: 0;
}

@media (hover: hover) {

  .card-header {
    border-bottom: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    /* width: 100%; */
    width: auto;
    z-index: 100;
  }

  .card:hover > .card-header {
    visibility: visible;
  }

  .card-tools {
    background: #fffbd1;
    border-radius: 5px;
  }
  
}