/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
html {
  height: auto;
}

ul {
  /*AdminLte fix*/
  margin-bottom: 0;
}

.layout-navbar-fixed .wrapper .content-wrapper {
  min-height: calc(100vh - calc(3.5rem + 1px));
  /* - 40px current selections height*/
}

.layout-navbar-fixed .wrapper .content-wrapper {
  padding-top: 40px; /* + current selections bar */
}

.main-header.current-selections {
  max-height: 40px;
  /* position: absolute !important; */
  top: calc(3.5rem + 1px) !important;
  background-color: #e6e6e6;
  z-index: 1018 !important;
}

.qv-global-selections {
  position: fixed;
  top: 96px;
  z-index: 1039 !important;
  height: calc(100% - 96px) !important;
}

.qv-global-search-container {
  position: fixed;
  top: 96px;
  z-index: 1040;
  height: auto;
}

.qv-global-search-container.queryon {
  position: fixed;
}

.lui-popover-container {
  margin-top: -54px;
  z-index: 9999;
}

/* fix adminlte box-sizing for qlik lui-popover */
.lui-popover-container *,
::after,
::before {
  box-sizing: content-box;
}

.qchart {
  position: relative;
}

.qchart * {
  /* padding: 0; */
  box-sizing: border-box;
}

.qchart.maximized {
  /* top: 84px; */
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* height: calc(100% - 38px); */
  /* max-height: calc(100% - 150px) !important; */
  /* position: absolute;
  height: calc(100% - 84px) !important;   */
}

/* .qdataview * {
  box-sizing: border-box;
} */

.qv-selection-toolbar *,
::after,
::before {
  box-sizing: content-box;
}

.qv-spopup-container {
  z-index: 1040 !important;
}

.qv-simplepopup {
  text-align: -webkit-right;
}

.sidebar {
  overflow-y: auto;
}

.sidebar-light-primary {
  background-color: #f4f6f9;
}

.content-header {
  padding: 8px 0.5rem;
}

.card-header {
  padding: 0.25rem 1.25rem;
}

.nav-item > .nav-link {
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: 234px;
}

.nav-link-black {
  color: black !important;
}

.nav-link-black:hover {
  color: #ffffff !important;
}

.sidebar-open .nav-item > .nav-link > p,
.sidebar:hover .nav-item > .nav-link > p {
  white-space: normal;
  min-width: 220px;
  padding-left: 5px;
}

.sidebar-closed .nav-item > .nav-link > p {
  visibility: hidden;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-image: linear-gradient(180deg, rgba(0,87,184,1) 10%, rgba(185,218,255,1) 100%);
  color: #fff;
}

.jumpapp {
  color: hsl(28, 70%, 56%) !important;
  /*rgb(0, 152, 69) !important;*/
}

.jumpapp:hover {
  /* background-color: #009845 !important; */
  background-color: hsl(28, 70%, 56%) !important;
  color: white !important;
}

.jumpappfeedback {
  color: #ffd500 !important;
  border-radius: 25px;
  margin-right: 5px !important; 
  font-weight: 400 !important;
  /*rgb(0, 152, 69) !important;*/
}

.jumpappfeedback:hover {
  /* background-color: #595959 !important; */
  background-color: black !important;
  border-radius: 5px;
  color: white !important;
  
}

.w-150px {
  width: 150px;
}

.page-title {
  font-weight: bold;
}

.card {
  box-shadow: none;
  margin-bottom: 2px;
}

.card-body {
  padding: 0;
}

.content {
  background-color: white;
}

.content-wrapper {
  background-color: white;
}

.prz-sale {
  /* background: linear-gradient(150deg, rgba(255,217,90,1) 0%, rgba(59,50,21,1) 20%, rgba(0,0,0,0.8016456582633054) 40%, rgba(0,0,0,1) 60%, rgba(59,50,21,1) 80%, rgba(255,217,90,1) 100%); */
  background: black;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 1rem;
}

.pushmenu-btn {
  /* color: rgb(128, 80, 180) !important; */
  color: black !important;
}

.main-footer {
  background-color: #f4f6f9;
  /* font-size-adjust: calc(100vw-1000px) !important; */
  font-size: 0.7vw;
}

.lang-btns {
  width: 150px;
}

.lang-btns-mobile {
  display: none;
}

.jumpappfeedback-mobile {
  display: none;
}

.filters-txt {
  padding-left: 2px;
  font-weight: bold;
  color:#000000 !important;
}

.filters-txt-active {
  padding-left: 2px;
  font-weight: bold;
  color:whitesmoke !important;

}

.filterpane-incative  {
  font-weight: bold;
}

.filterpane-active  {
  font-weight: bold;
}

.login-txt {
  font-weight: bold;
}

.w-lang-mobile {
  width: 60px;
  font-size: 1rem;
}

@media screen and (max-width: 640px) {

  .control-sidebar-slide-open .control-sidebar {
    width: calc(100vw) !important;
  }

  #JaepXAd, #aFtbcD, #FaDpUt, #WPNRd, #eDgQuEk, #cEgjTG {
    height: 38px !important;
  }

  #GDdpuRm, #ushNqJz, #kdCjkcp, #PbDfk, #peDqhEW, #FdtTXL {
    height: 40px !important;
  }

  .lang-btns, .filters-txt, .login-txt {
    display: none;
  }

  .jumpappfeedback {
    display: none;
  }

  .lang-btns-mobile {
    display: block;
    margin-bottom: 0.5rem;
  }

  .jumpappfeedback-mobile {
    display: block;
    margin-bottom: 0.5rem;
  }

  .w-lang-mobile {
    font-size: 0.8rem;
  }

  .prz-sale {
    font-size: 1.25rem;
    margin-left: 0.5rem;
  }

  .filterpane-sheet {
    display: none;
  }

  #rAajTDh {
    max-height: calc(100vh - 284px);
    min-height: calc(100vh - 284px);
  }

  #HJHDc, #wfxvhWq, #fNhQvq, #ad0460f4-1da6-4efe-8664-23ccfac64c3d {
    min-height: calc(min(calc(100vh - 184px), 300px));
    max-height: calc(min(calc(100vh - 184px), 300px));
  }

  #AvjgfP, #avVcA, #QwjCuw, #EJzkRp, #EVZJW, #czJqRnG {
    max-height: 300px;
  }

  #VjjfeX, #zEPatjx {
    max-height: 160px;
  }

  #GhVZpW, #a1c80bf0-040e-4988-8ca1-f694ef28a2fb, #FKymsum, #d640c3c3-454b-40b9-850c-ef92fbe23ad2, #facebb14-f0ea-4c65-8975-3b7cbdc95886, #QALUNss, #JuDbUqN, #dCvpLK, #6be31d9b-70a4-4528-ac55-2f6ddced29d7  {
    max-height: 0px;
  }

  #mjUsR {
    max-height: 250px;
    min-height: 250px;
  }

  .main-footer {
    font-size: 11px;
  }

}

@media screen and (max-width: 400px) {

  .control-sidebar-slide-open .control-sidebar {
    width: calc(100vw) !important;
  }

  #JaepXAd, #aFtbcD, #FaDpUt, #WPNRd, #eDgQuEk, #cEgjTG {
    height: 38px !important;
  }

  #GDdpuRm, #ushNqJz, #kdCjkcp, #PbDfk, #peDqhEW, #FdtTXL {
    height: 40px !important;
  }

  .prz-sale {
    font-size: 0.95rem;
    margin-left: 0;
  }

  #rAajTDh {
    max-height: calc(100vh - 284px);
    min-height: calc(100vh - 284px);
  }

  #HJHDc, #wfxvhWq, #fNhQvq, #ad0460f4-1da6-4efe-8664-23ccfac64c3d {
    min-height: calc(min(calc(100vh - 184px), 300px));
    max-height: calc(min(calc(100vh - 184px), 300px));
  }

  #AvjgfP, #avVcA, #QwjCuw, #EJzkRp, #EVZJW, #czJqRnG {
    max-height: 300px;
  }

  #VjjfeX, #zEPatjx {
    max-height: 160px;
  }

  #GhVZpW, #a1c80bf0-040e-4988-8ca1-f694ef28a2fb, #FKymsum, #d640c3c3-454b-40b9-850c-ef92fbe23ad2, #facebb14-f0ea-4c65-8975-3b7cbdc95886, #QALUNss, #JuDbUqN, #dCvpLK, #6be31d9b-70a4-4528-ac55-2f6ddced29d7, #01616202-88b8-4e32-8d8f-36355fbc21c9 {
    max-height: 0px;
  }

  #mjUsR {
    max-height: 250px;
    min-height: 250px;
  }

  .main-footer {
    font-size: 10px;
  }
}

@media screen and (max-width: 1000px) {

  .control-sidebar-slide-open .control-sidebar {
    width: calc(100vw) !important;
  }

  #JaepXAd, #aFtbcD, #FaDpUt, #WPNRd, #eDgQuEk, #cEgjTG {
    height: 38px !important;
  }

  #GDdpuRm, #ushNqJz, #kdCjkcp, #PbDfk, #peDqhEW, #FdtTXL {
    height: 40px !important;
  }

  #GhVZpW, #a1c80bf0-040e-4988-8ca1-f694ef28a2fb, #FKymsum, #d640c3c3-454b-40b9-850c-ef92fbe23ad2, #facebb14-f0ea-4c65-8975-3b7cbdc95886, #QALUNss, #JuDbUqN, #dCvpLK, #01616202-88b8-4e32-8d8f-36355fbc21c9  {
    max-height: 0px;
  }

  #rAajTDh {
    max-height: calc(100vh - 204px);
    min-height: calc(100vh - 204px);
  }

  #HJHDc, #wfxvhWq, #fNhQvq, #ad0460f4-1da6-4efe-8664-23ccfac64c3d {
    min-height: calc(min(calc(100vh - 184px), 300px));
    max-height: calc(min(calc(100vh - 184px), 300px));
  }

  #mjUsR {
    max-height: 250px;
    min-height: 250px;
  }

  .main-footer {
    font-size: 11px;
  }

}

@media screen and (max-width: 1420px) {
  .main-footer {
    font-size: 14px;
  }
}

@media screen and (max-width: 1220px) {
  .md-justify-content-left-manual {
    float: left !important;
    justify-content: left !important;
  }
}

.lang-btns .lui-button.selected, .lang-btns-mobile .lui-button.selected {
  background-color: #000000 !important;
  outline-color: #ffd500;
}

.right-divider {
  border-right: dashed 1px lightgray;
  border-radius: 0px;
}

.nav-sidebar > .nav-item .nav-icon {
  font-size: 1rem;
}

.qlik-daterangepicker.dropdown-menu.show-calendar {
  z-index: 1080 !important;
}

.mt20 {
  margin-top: 20px;
}

.top-divider {
  border-top: dashed 1px lightgray;
  border-radius: 0px;
}

.bottom-divider {
  border-bottom: dashed 1px lightgray;
  border-radius: 0px;
  margin-bottom: 10px;
}

#mXJEnBE_title {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse
}

.btn-auth {
  color: rgba(0,0,0,.5);
  padding-left: 16px;
  padding-right: 16px;
}

.btn-auth:hover {
  /* background-color: #009845 !important; */
  color: #000000 !important;
  border-radius: 2px;
}

.lui-tooltip {    
  z-index: 2048 !important;
}

.lui-tooltip { 
  position: fixed !important;
}

/* #AvjgfP_title {
  margin-top: -30px;
} */

body, html {
  font-family: Rubik, sans-serif;
}

.filterpane-active {
  border-radius: 5px;
  background-color: black;
  color: white !important;
};

.btn-connectionlost-reload {
  background-color: #000000 !important;
  border-color: #ffd500 !important;
}

.page-disclaimer {
  font-style: italic;
}

.appHelp {
  margin-right: 5px !important; 
  margin-left: 0px !important; 
  font-weight: 400 !important;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 29px;
}

.ml5 {
  margin-left: 5px;
}

.css-1x3zsj9 .RowColumn-S {
  background-color: black !important;
  border-bottom: 1px solid #000000 !important;
  color: #fff !important;
}

[class^="css-"] .RowColumn-S {
  background-color: black !important;
  border-bottom: 1px solid #000000 !important;
}

[class^="css-"] .RowColumn-S .RowColumn-labelText {
  color: white !important;
}

[class^="css-"] .RowColumn-S .RowColumn-icon {
  color: white !important
}

.RowColumn-labelText { 
  text-align: left !important;
  }


.qv-listbox .serverLocked, .qv-listbox .serverSelected, .qv-listbox li.selected, .qv-st-data-cell.qv-st-data-cell-selected , .qv-st-data-cell.qv-st-data-cell-selected-highlighted  {
  background-color: #000000 !important;
  border-bottom: 1px solid #000000 !important;
  color: #fff !important;
}/* Selector green bar */
.qv-client.qv-dense qv-filterpane.div.state.selected {
	background: #000000 !important;
}
/* Listbox handling blue background */
.qv-client.qv-dense li.qv-listbox-item.ng-scope.serverSelected {
	background-color: #000000 !important;
	border-bottom-color: #000000 !important;
}

.qv-state-count-bar .state.selected {
  background-color: #000000 !important;
}

.MuiGrid-root.MuiGrid-item[class^="css-"] [style*="background: rgb(0, 152, 69);"]  {
  background-color: #000000 !important;
}

.MuiGrid-root.MuiGrid-item > div [style*="background: rgb(0, 152, 69);"]  {
  background-color: #000000 !important;
}

.qv-object-sn-pivot-table.qv-selections-active > div [style*="background: rgb(10, 175, 84);"] {
  background-color: #000000 !important;
}

.qv-object-sn-pivot-table.qv-selections-active > div [style*="background: rgb(10, 175, 84);"] > div > svg {
  opacity: 1 !important;
}





.as-of-date-disclaimer {
  /* padding: 5px; */
  /* justify-content: center; */
  text-align: center;
  bottom: 0px;
  position: absolute;
}

 .as-of-date-disclaimer {
  display: none;
}

/* .sidebar-closed .nav-item > .nav-link > p {
  visibility: hidden;
} */


.sidebar-open .as-of-date-disclaimer, .sidebar:hover .as-of-date-disclaimer {
  display:block;
  width: calc(100% - 1rem);
}

.chart-overlayer {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  display: block;  
}

.btn-success {
  background-color: #000000 !important;
  border-color: #ffd500 !important;
}

.card-maximized .card-header {
  position: relative !important
}

.range-slider-std {
  margin-left: 8px;
  margin-right: 8px;
}

.qv-object-qlik-multi-kpi { 
  display:none; 
  vertical-align: middle;
} 
.qv-mode-edit .qv-object-qlik-multi-kpi { 
  display:flex; 
  vertical-align: middle;
}

.control-sidebar-slide-open .control-sidebar {
  width: calc(100vw - 50px - 1.5rem);
  height: calc(100vh -(3.5rem + 1px));
}

.control-sidebar-slide-open .control-sidebar, .control-sidebar-slide-open .control-sidebar::before {
  transition: left .1s ease-in-out, display .1s ease-in-out
}

.filterpane-incative {
  color: #000000 !important;
}

/* .css-1hq4e26 .RowColumn-S, .css-f5er97 .RowColumn-S  {
  background: #000000 !important;
} */


.css-1nwu5vb.ListBox-styledScrollbars {
  scrollbar-color:inherit;
}

.qv-listbox li.serverExcluded, .qv-listbox li.serverExcludedLock, .qv-listbox li.serverSelectedExcluded {
  color: #000000 !important
}

.qv-listbox li.serverExcluded, .qv-listbox li.serverExcludedLock, .qv-listbox li.serverSelectedExcluded {
  color: #000000 !important
}

/* .css-1nwu5vb.ListBox-styledScrollbars {
  position: fixed !important;
} */

.nav-link.active.filterpane-active {
  background-color: #222; 
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); 
}

/* .MuiGrid-root.MuiGrid-container.css-9lmsw8 .MuiGrid-root.MuiGrid-item[style*="background: rgb(0, 152, 69);"] {
  color: black !important;
} */

.justified-text {
  text-align: justify;
  width: 100%; /* Adjust width as needed */
}

.qv-object-sn-pivot-table > div > div > svg {
  padding: 0px !important;
}

svg[data-testid="expand-icon"]  {
  padding: 0px !important;
  margin-top: 4.5px !important;
} 

svg[data-testid="collapse-icon"] {
  padding: 0px !important;
  margin-top: 4.5px !important;
} 

.lui-tab.lui-active {
  border-bottom: 2px solid #000000 !important;
}



.qv-object-simple-list .lui-checkbox__check-wrap{ 
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block;
}

.qv-object-simple-list.qv-object-sl-touch {
  overflow-x:hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  width: 100%;
}

/* .lui-checkbox__check-text>span {
  overflow-x:hidden!important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  width: 100%;
} */

/* .lui-checkbox .lui-checkbox__check-text {
  overflow-x:hidden!important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  width: 100%;
} */

.lui-checkbox__check-wrap.check-wrap {
  width: calc(100% - 5px); /* Subtract scrollbar width from the total width */
  box-sizing: border-box; /* Include padding and border in the width */
  overflow: hidden; /* Prevent overflow of content */
}

.lui-checkbox__check-wrap.check-wrap .lui-checkbox__check-text {
  white-space: nowrap;           /* Prevent text from wrapping */
  overflow: hidden;              /* Hide any text that overflows */
  text-overflow: ellipsis;       /* Show ellipsis for overflowed text */
  display: inline-block;         /* Treat span like a block element while keeping it inline */
  width: calc(100% - 20px);      /* Adjust width to account for scrollbar */
  max-width: 100%;               /* Ensure the span doesn't exceed its parent's width */
  box-sizing: border-box;        /* Include padding/border in width calculations */
}

.no-padding-buttons {
  padding-left: 0px;
  padding-right: 0px;
}

.padding-left-buttons {
  padding-left: 11px;
}

.padding-right-buttons {
  padding-right: 11px;
}

/* #PJkuD button text {
  font-size: 7px !important;
} */

@media (min-width: 1200px) {
  .col-xl-1-7 {
    flex: 0 0 14.2857%;
    max-width: 14.2857%;
  }
}

@media (max-height: 800px) {
  #NZJHBTZ {
    max-height: max(calc(100vh - 197px - 122px)/2 - 30px - 7px, 100px) !important;
    min-height: max(calc(100vh - 197px - 122px)/2 - 30px - 7px, 100px) !important;
  }
}

@media (max-height: 800px) {
  #BHbmUy {
    max-height: max(calc(100vh - 197px - 122px)/2 - 30px - 7px, 100px) !important;
    min-height: max(calc(100vh - 197px - 122px)/2 - 30px - 7px, 100px) !important;
  }
}

.introjs-helperLayer {
  position:fixed !important;
}

.introjs-tooltip {
  position: fixed; 
  z-index: 1000 !important;
  display: inline !important;
}

.start-tour-button {
  background-color: #ffffff; /* White background */
  color: #000000; /* Black text */
  border: 1px solid #000000; /* Black border */
  border-radius: 5px; /* Rounded corners */
  height: 40px; /* Fixed height */
  width: 40px; /* Fixed width when not hovered */
  padding: 0; /* Remove inner padding */
  font-size: 16px; /* Font size for text */
  font-weight: bold; /* Bold text */
  display: inline-flex; /* Align icon/text */
  align-items: center; /* Center icon/text vertically */
  justify-content: center; /* Center icon/text horizontally */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, color 0.3s, width 0.3s; /* Smooth transitions */
}

.start-tour-button:hover {
  background-color: #ffffff; /* White background on hover */
  color: #000000; /* Black text on hover */
  width: auto; /* Expand to fit text when hovered */
  padding: 0 15px; /* Add padding for the text */
  border: 1px solid #000000; /* Thicker black border on hover */
}

@media (max-width: 1199px) {
  .start-tour-button {
    display: none; /* Hide the button on small screens */
  }
}


.custom-tooltip-tour-home-kpi-row {
  margin-top: -60px !important;
}

.custom-tooltip-tour-home-kpi-financial-coverage {
  margin-top: -60px !important;
}

.custom-tooltip-tour-home-barchart-kpi-selection {
  margin-bottom: 60px !important;
}

.css-1drouzf input {
  box-sizing: content-box !important;
}

.lui-popover-container *, ::after, ::before {
  box-sizing: border-box;
}

.lui-checkbox .lui-checkbox__input:checked+.lui-checkbox__check-wrap .lui-checkbox__check {
  background: #000000 !important;
}
